import React, { RefObject } from 'react'

import {CarouselBannersContainer} from './carouselContainer.module.css'

interface CarouselContainerProps {
    refObj: RefObject;
    onScroll: () => void;
}

const CarouselContainer = ({children, refObj, onScroll}) => {
    return (
        <div ref={refObj} onScroll={onScroll} className={CarouselBannersContainer}>
            {children}
        </div>
    )
}

export default CarouselContainer
