import CatergoriesStripItemIcon from './CatergoriesStripItemIcon/categoriesStripItemIcon';
import { CategoriesStripItem as CategoriesStripItemClass, CategoriesStripItemLabel } from './categoriesStripItem.module.css';
import { ProductType } from '../../../types/types';
import { capitalizeFirstLetter } from '../../../Utils/utils';

import React from 'react';
import { Link } from 'gatsby';
import ButtonBase from '@material-ui/core/ButtonBase';

interface CategoriesStripItemProps extends ProductType {

}

const CategoriesStripItem = ({ type, url }: CategoriesStripItemProps) => (
  <Link to={`/${url}`} className={CategoriesStripItemClass}>
    <ButtonBase
      focusRipple
      disableTouchRipple
      className={CategoriesStripItemClass}
    >
      <CatergoriesStripItemIcon type={capitalizeFirstLetter(type)} />
      <span className={CategoriesStripItemLabel}>{capitalizeFirstLetter(type.trim())}</span>
    </ButtonBase>
  </Link>
);

export default CategoriesStripItem;
