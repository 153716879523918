import BentoIcon from './icons/bento-min.svg';
import MochiIcon from './icons/mochi-min.svg';
import OkonoIcon from './icons/okonomiyaki-min.svg';
import RamenIcon from './icons/ramen-min.svg';
import HotspotIcon from './icons/hotspot-min.svg';
import SakeIcon from './icons/sake-min.svg';
import SaladIcon from './icons/salad-min.svg';
import DiscountIcon from './icons/discount.svg';
import SashimiIcon from './icons/sashimi-min.svg';
import GunkanIcon from './icons/gunkan-min.svg';
import SpringIcon from './icons/springrolls-min.svg';
import SushiIcon from './icons/sushi-min.svg';
import SushirritoIcon from './icons/sushirrito-min.svg';
import SoysauceIcon from './icons/soysauce-min.svg';
import DiyIcon from './icons/diy-min.svg';
import RiceIcon from './icons/rice-min.svg';
import BlinIcon from './icons/blin-min.svg';
import NoodlesIcon from './icons/noodles-min.svg';
import { ProductType } from '../../../../types/types';
import React from 'react';

interface CategoriesStripItemIconProps extends ProductType{
}

const CategoriesStripItemIcon = ({ type }: CategoriesStripItemIconProps) => {
  const Icon = () => {
    switch (type.trim()) {
      case 'Роллы':
        return <SushiIcon />;
      case 'Обеды':
        return <HotspotIcon />;
      case 'Гунканы':
        return <GunkanIcon />;
      case 'Пицца «Собери сам»':
        return <DiyIcon />;
      case 'Наборы роллов':
      case 'Наборы':
        return <BentoIcon />;
      case 'Десерты':
        return <MochiIcon />;
      case 'Супы':
        return <RamenIcon />;
      case 'Блины':
        return <BlinIcon />;
      case 'Салаты':
        return <SaladIcon />;
      case 'Горячее':
        return <NoodlesIcon />;
      case 'Нигири':
        return <SashimiIcon />;
      case 'Спрингроллы':
        return <SpringIcon />;
      case 'Роллы со скидкой 50%':
        return <DiscountIcon />;
      case 'Суширрито':
        return <SushirritoIcon />;
      case 'Напитки':
        return <SakeIcon />;
      case 'Добавки':
        return <SoysauceIcon />;
      case 'Пицца':
        return <OkonoIcon />;
      default:
        return <RiceIcon />;
    }
  };

  return (
    <div>
      <Icon />
    </div>
  );
};

export default CategoriesStripItemIcon;
