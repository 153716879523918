import React, { HTMLAttributes } from 'react'

import {H1 as H1Class} from './H1.module.css'

interface H1Props extends HTMLAttributes<HTMLElement>{
    className?: string;
}

const H1 = ({children, className}: H1Props) => {
    return (
        <h1 className={className ? `${H1Class} ${className}` : H1Class} >
            {children}
        </h1>
    )
}

export default H1
