import { Carousel as CarouselClass } from './carousel.module.css';
import CarouselButton from './CarouselButton/carouselButton';
import CarouselContainer from './CarouselContainer/carouselContainer';
import Banner from '../Banner/banner';
import { BannerContainer, Banner as BannerClass } from '../Banner/banner.module.css';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

interface CarouselProps {
    arr?: []
}

const Carousel = ({ arr }) => {
  const [visibleSlide, setVisibleSlide] = useState(0);
  const [targetSlide, setTargetSlide] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const wrapperRef = useRef(null);
  const targetSlideRef = useRef(null);

  const scrollToTargetSlide = useCallback(() => {
    const targetSlide = targetSlideRef.current;
    const wrapper = wrapperRef.current;

    if (wrapper && targetSlide) {
      wrapper.scrollTo({
        top: 0,
        left: targetSlide.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, []);

  const finishScrolling = useCallback(() => {
    setTargetSlide(visibleSlide);
    scrollToTargetSlide();
  }, [visibleSlide, scrollToTargetSlide]);

  const touchScroll = useIdle({ timeout: 150, onIdle: finishScrolling });

  const moveLeft = useCallback((targetSlide) => Math.max(0, targetSlide - 1), []);

  const moveRight = useCallback(
    (targetSlide) => Math.min(targetSlide + 1, arr.length - 1),
    [arr],
  );

  const handleScroll = useCallback(() => {
    const { width } = wrapperRef.current.getBoundingClientRect();
    const { scrollLeft } = wrapperRef.current;
    const nextSlide = Math.round(scrollLeft / width);

    setVisibleSlide(nextSlide);
    touchScroll();
  }, [touchScroll]);

  const handleLeftButtonDisplay = () => {
    visibleSlide !== 0 ? setShowLeftButton(true) : setShowLeftButton(false);
  };

  const handleRightButtonDisplay = () => {
    visibleSlide === arr.length - 1 ? setShowRightButton(false) : setShowRightButton(true);
  };

  useEffect(scrollToTargetSlide, [targetSlide]);

  useEffect(() => {
    if (arr) {
      handleRightButtonDisplay();
      handleLeftButtonDisplay();
    }
  }, [visibleSlide]);

  return (
    <div className={CarouselClass}>
      {showLeftButton && <CarouselButton type="left" onClick={() => setTargetSlide(moveLeft)} />}

      <CarouselContainer refObj={wrapperRef} onScroll={handleScroll}>
        {arr.map((val, ind) => (
          <Banner
            key={val.id}
            refObj={ind === targetSlide ? targetSlideRef : null}
            description={val.Description}
            heading={val.Heading}
            needDarkTextColor={val.needDarkTextColor}
            image={val.Image}
            url={val.Link}
            textBackgroundHex={val.textBackgroundHex}
          />
        ))}
      </CarouselContainer>

      {showRightButton && <CarouselButton type="right" onClick={() => setTargetSlide(moveRight)} />}
    </div>
  );
};

// Hook that performs `onIdle` when it is not "touched" for `timeout` milliseconds
const useIdle = ({ timeout, onIdle }) => {
  const [state, setState] = useState(Object.create(null));

  useEffect(() => {
    const t = setTimeout(onIdle, timeout);
    return () => clearTimeout(t);
  }, [onIdle, timeout, state]);

  const touch = useCallback(() => setState(Object.create(null)), []);
  return touch;
};

export default Carousel;
