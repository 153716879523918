import CategoriesStripItem from './CategoriesStripItem/categoriesStripItem';
import {
  CategoriesStripWrapper, CategoriesStripWrapperWrapper, CategoriesStripLeftbuttonContainer, CategoriesStripLeftbutton, CategoriesStripButtonHidden, CategoriesStripRightbuttonContainer, CategoriesStrip as CategoriesStripClass, CategoriesStripItemContainer, CategoriesStripRightbutton,
} from './categoriesStrip.module.css';
import ScrollButton from '../Blocks/ScrollButton/scrollButton';
import { useScrollPosition } from '../../Utils/useScroll/useScrollPosition';
import React, { useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

interface CategoriesStripProps {
    setShowHeaderCategories: (arg: boolean) => void;
}

const CategoriesStrip = ({ setShowHeaderCategories }: CategoriesStripProps) => {
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const categoriesRef = useRef(null);

  const data = useStaticQuery(graphql`
    {
      allStrapiCategories(sort: {fields: Order, order: ASC}) {
        nodes {
          Name
          id
          URL
        }
      }
    }`);

  const scroll = (scrollOffset) => {
    if (categoriesRef.current !== undefined) {
      categoriesRef.current.scrollLeft += scrollOffset;
    }
  };

  useScrollPosition(
    ({ currPos }) => {
      if (categoriesRef !== null) {
        setShowHeaderCategories(currPos.y < 0);
      }
    },
    [], categoriesRef,
  );

  const handleHorizontalScroll = () => {
    const scrollWidth = categoriesRef.current.scrollWidth - categoriesRef.current.clientWidth;
    const scrollPosition = categoriesRef.current.scrollLeft;

    if (scrollPosition > 0) {
      setShowLeftButton(true);
    } else {
      setShowLeftButton(false);
    }

    if (scrollPosition >= scrollWidth) {
      setShowRightButton(false);
    } else {
      setShowRightButton(true);
    }
  };

  return (
    <div className={CategoriesStripWrapper}>
      <div className={CategoriesStripWrapperWrapper}>

        <div className={showLeftButton
          ? CategoriesStripLeftbuttonContainer
          : CategoriesStripButtonHidden}
        >
          <ScrollButton className={CategoriesStripLeftbutton} type="left" onClick={() => scroll(-100)} />
        </div>

        <div className={showRightButton
          ? CategoriesStripRightbuttonContainer
          : CategoriesStripButtonHidden}
        >
          <ScrollButton className={CategoriesStripRightbutton} type="right" onClick={() => scroll(100)} />
        </div>

        <div ref={categoriesRef} onScroll={handleHorizontalScroll} className={CategoriesStripClass}>
          <div className={CategoriesStripItemContainer}>
            {data.allStrapiCategories.nodes.map((val) => <CategoriesStripItem url={val.URL} type={val.Name} key={`${val.Name}-${val.id}`} />)}
          </div>
        </div>

      </div>
    </div>
  );
};

export default CategoriesStrip;
