import {
  BannerContainer, Banner as BannerClass, BannerTextContainer, BannerTextContainerBL, BannerImage,
} from './banner.module.css';
import H1 from '../Blocks/H1/H1';
import Paragraph from '../Blocks/Paragraph/paragraph';
import React, { RefObject } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface BannerProps {
    refObj: RefObject<HTMLDivElement>
    image: string;
    heading: string;
    description: string;
    link: string;
    needDarkTextColor: boolean;
}

const useStyles = makeStyles((theme) => ({
  spanStyle: {

  },
  textStyle: (props) => ({
    '-webkit-user-select': 'none', /* Safari */
    '-moz-user-select': 'none', /* Firefox */
    '-ms-user-select': 'none', /* IE10+/Edge */
    userSelect: 'none', /* Standard */
    padding: '0.6rem',
    backgroundColor: props.textBackgroundHex !== null ? props.textBackgroundHex : '#FFEEDA',
    borderRadius: '12px',
    boxDecorationBreak: 'clone',
    '-webkit-box-decoration-break': 'clone',
    [theme.breakpoints.down('sm')]: {
      padding: '0.3rem',
      borderRadius: '8px',
    },
  }),
}));

const Banner = ({
  refObj, image, heading, description, needDarkTextColor, url, textBackgroundHex,
}: BannerProps) => {
  const classes = useStyles({ textBackgroundHex });

  return url
    ? (
      <a href={url} ref={refObj} className={BannerContainer}>
        <div className={BannerClass}>
          <div className={needDarkTextColor ? BannerTextContainerBL : BannerTextContainer}>
            <H1><span className={classes.textStyle}>{heading}</span></H1>
            {description && description !== '' && <Paragraph><span className={classes.textStyle}>{description}</span></Paragraph>}

          </div>
          {image.localFile && (
          <GatsbyImage
            imgStyle={{ objectFit: 'cover' }}
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={heading}
            className={BannerImage}
          />
          )}
          )

        </div>
      </a>
    )
    : (
      <div ref={refObj} className={BannerContainer}>
        <div className={BannerClass}>
          <div className={needDarkTextColor ? BannerTextContainerBL : BannerTextContainer}>
            <H1><span className={classes.textStyle}>{heading}</span></H1>
            {description && description !== '' && <Paragraph><span className={classes.textStyle}>{description}</span></Paragraph>}

          </div>
          {image.localFile && (
          <GatsbyImage
            imgStyle={{ objectFit: 'cover' }}
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={heading}
            className={BannerImage}
          />
          )}
          )

        </div>
      </div>
    );
};

export default Banner;
