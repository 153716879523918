import React from 'react'

import ArrowLeftIcon from './icons/arrow-left.svg'
import ArrowRightIcon from './icons/arrow-right.svg'

import * as styles from './carouselButton.module.css'

interface CarouselButtonProps {
    onClick: () => void;
    type: 'Left' | 'Right';
}

const CarouselButton = ({onClick, type}) => {
    const Icon = () => type === 'left' ? <ArrowLeftIcon/> : <ArrowRightIcon/> 
    return (
        <div onClick={onClick} className={`${styles[`CarouselButton`]} ${styles[`CarouselButton${type.toUpperCase()}`]}`}>
            <Icon />
        </div>
    )
}

export default CarouselButton
